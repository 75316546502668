<div class="pt-5 text-center mb-4">
</div>

<div class="with-border">
    <div class="padding15">
        <mat-form-field class="" appearance="standard"> 
            <mat-label>Cerca ordine</mat-label> 
            <input matInput (keyup)="applyFilter($event)" #input> 
        </mat-form-field>
    </div>
    <div class="tableCont">
        <table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="dataordine" matSortDirection="desc">

            <ng-container matColumnDef="idOrdine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id Ordine</th>
                <td mat-cell *matCellDef="let element">
                    {{element.idordine}}</td>
            </ng-container>

            <ng-container matColumnDef="brandDescrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element"> {{element.brandDescrizione}} </td>
            </ng-container>

            <ng-container matColumnDef="dataordine">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
                <td mat-cell *matCellDef="let element"> {{element.dataordine | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="statoOrdineDescrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato ordine </th>
                <td mat-cell *matCellDef="let element">
                    {{element.statopraticaDescrizione}}</td>
            </ng-container>

            <ng-container matColumnDef="statopraticaDescrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered-header"> Stato pratica </th>
                <td mat-cell *matCellDef="let element" class="centered-cell">
                    {{element.attivazioneServizi?.statorichiestaDescrizione ? element.attivazioneServizi?.statorichiestaDescrizione  : '-'}}</td>
            </ng-container>

            <ng-container matColumnDef="nomeProdotto" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Prodotto </th>
                <td mat-cell *matCellDef="let element">
                    {{element.nomeProdotto}}
                </td>
            </ng-container>

            <ng-container matColumnDef="clienteCf">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nominativo Cliente </th>
                <td mat-cell *matCellDef="let element">
                    {{element.cognome}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="lastCl lastClBis actionCss"></th>
                <td mat-cell *matCellDef="let element" class="lastCl lastClBis actionCss">

                    <button mat-icon-button 
                        [matMenuTriggerFor]="menu" 
                        [disabled]="!hasMenuItems(element)">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item 
                            *ngIf="element.statopraticaCodice === 'F' && element.attivazioneServizi?.statorichiestaCodice !== 'F' && element.attivazioneServizi?.statorichiestaCodice !== 'R'"
                            (click)="goToOrder(element.prodottoCommessa, element.id)">
                            <span class="material-icons">open_in_new</span> Compila
                      </button>
                  
                      <button mat-menu-item 
                            *ngIf="element.attivazioneServizi?.statorichiestaCodice === 'F'"
                            (click)="formController.openPdf(true, element.attivazioneServizi.idfile, element.attivazioneServizi.idProcedure)">
                            <span class="material-icons">preview</span> Anteprima
                      </button>
                  
                      <button mat-menu-item 
                            *ngIf="element.attivazioneServizi?.statorichiestaCodice === 'F'"
                            (click)="formController.downloadPdf(true, element.idordine, element.attivazioneServizi.idfile, element.attivazioneServizi.idProcedure, constants.CONTRATTO_ATT_SERV)">
                            <span class="material-icons">cloud_download</span> Download
                      </button>                        
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</div>