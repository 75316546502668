import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { constants } from 'src/app/modules/classes/constants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.scss']
})
export class ImpersonateUserComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading: boolean = false;
  userList: any[] = [];

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['login', 'activated', 'authorities', 'actions'];

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private formController: FormControllerService,
  ) {
  }

  ngOnInit(): void {
    if (this.userService.returnUserAut(constants.TYPE_ROLE_SUPPORT)) {
      this.getContracts();
    }
  }

  getContracts(): any {
    this.loading = true;
    this.userService.getAllUser('?size=10000').subscribe(res => {
      this.loading = false;
      this.userList = res;
     
      // Filtra solo gli utenti con ROLE_SELLER
      this.userList = res.filter(user => user.authorities.includes("ROLE_SELLER"));

      this.dataSource = new MatTableDataSource(this.userList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }

  loginAs(user: any): void {
    this.authService.loginAs(user).subscribe((res:any) => {
      this.authService.switchUser(res);
      if(user?.login){
        this.getUserDetails(user.login)
      }
    }, error => {
        this.formController.openSnackBar(error);
        console.error('Error => ', error);
    });
  }

  getUserDetails(login: string): void {
    this.userService.getUser(login).subscribe(res => {
      this.authService.setProfile(res);
      window.dispatchEvent(new Event('storage'));
    }, error => {
      this.loading = false;
      this.formController.openSnackBar(error);
      console.error('Error => ', error);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return (
        data.login.toLowerCase().includes(filter) || 
        data.authorities.some(auth => auth.toLowerCase().includes(filter))
      );
    };

    this.dataSource.filter = filterValue;
  }
}
