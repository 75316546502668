import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { Signature } from '../../../../classes/signature';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Ordine } from '../../../../classes/ordine';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { BrandService } from '../../../../../services/brand/brand.service';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { CaldaiaService } from './services/caldaia.service';
import { DettagliocaldaiaDTO } from '../../../../classes/caldaie/dettagliocaldaia-dto';
import { DisegnocaldaiaDTO } from '../../../../classes/caldaie/disegnocaldaia-dto';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { AbitazioneDTO } from '../../ariel-main/classes/abitazione-dto';
import { AbitazioneService } from '../../ariel-main/services/abitazione.service';
import { AccessoricaldaiaDTO } from '../../../../classes/caldaie/accessoricaldaia-dto';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import { environment } from 'src/environments/environment';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { constants } from 'src/app/modules/classes/constants';
import * as $ from 'jquery';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
declare var jQuery: any;
@Component({
	selector: 'app-caldaia',
	templateUrl: './caldaia.component.html',
	styleUrls: ['./caldaia.component.scss']
})
export class CaldaiaComponent implements OnInit, DoCheck, AfterViewInit {

	generatingPdf = false;
	showFrame = false;
	openSign: boolean = false;
	sendingPdf: boolean = false;
	showModalPrint: boolean = false;
	confirmCaldaia: boolean = false;
	validAnagrafica: boolean = false;
	isFinanziamentoPayment: boolean = false;

	currentStep: number = 0;
	valueProgBar = 0;
	currentPrinting = 0;
	idCliente: number;

	urlFrame: string = "";

	formDiffer: any;
	originalForm: any;
	obj1: any;
	obj2: any;
	printContent: any;
	form: any;
	pdf: any;
	error: any;
	member: any;
	signature: Signature;

	anagrafica: AnagraficaDTO;
	trascodifiche: Trascodifica[];
	listFinanziarie: Trascodifica[];
	attivitaLavList: Trascodifica[] = [];
	totalSteps: number[];
	ordine: Ordine;

	finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'caldaia', brand: 'Ariel'
	}

	integratedSignature = environment.integratedSignature;
	hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

	constructor(
		public formController: FormControllerService,
		public userService: UserService,
		private anagraficaService: AnagraficaService,
		private brandService: BrandService,
		private route: ActivatedRoute,
		private orderService: OrdersService,
		private paymentService: PaymentService,
		private caldaiaService: CaldaiaService,
		private abitazioneService: AbitazioneService,
		private omaggioService: OmaggioService,
		private allegatoService: AllegatoService,
	) {
		this.showModalPrint = false;
		this.validAnagrafica = false;
		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('id')),
			anagrafica: new AnagraficaForm(),
			sections: {
				caldaie: [],
				dettaglioCaldaia: new DettagliocaldaiaDTO(),
				abitazione: new AbitazioneDTO(),
				pagamento: [new PagamentoDTO()],
				finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
				disegnoCaldaia: new DisegnocaldaiaDTO(),
				accessori: new AccessoricaldaiaDTO(),
				ordine: null,
				omaggio: [new OmaggioDto()],
			}
		};

		this.totalSteps = Array(9).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
	}

	getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);
			if (res.clienteId !== undefined && res.clienteId !== null) {
				localStorage.setItem('id_client', res.clienteId);
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}

			localStorage.setItem('id_order', res.idordine);
		});
		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
			this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
		});
		this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
	}

	ngOnInit(): void {
		this.originalForm = JSON.parse(JSON.stringify(this.form));

		if (localStorage.getItem('id_client') !== null) {
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.getOrder(this.form.idOrder);
	}

	ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}

	ngAfterViewInit(): void {
		console.info('origina form', this.originalForm);
	}

	stepController(): boolean {
		switch (this.currentStep) {
			case 0:
			case 7:
				return this.generatingPdf || !this.validAnagrafica;
			case 1:
				return this.generatingPdf || !this.validCaldaie() || this.formController.checkNoteLength(this.form.sections.dettaglioCaldaia.note, 255);
			case 2:
				return this.generatingPdf || !this.validDatiAbitazione() || !this.validDisegnoCaldaia();
			case 3:
				return this.generatingPdf || !this.validAccessori() || !this.validCannaFumaria() || !this.validDettagliInstallazione() ||  this.formController.checkNoteLength(this.form.sections.accessori.note, 255);
			case 4:
				return this.generatingPdf || !this.formController.validOmaggio(this.form) || this.form.sections.omaggio.length == 0;
			case 5:
				return this.generatingPdf || !this.formController.validPagamento(this.form, false, true);
			case 6:
				return this.generatingPdf || !this.formController.validPrivacy(this.form);
			case 8:
				return this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie);
			default:
				return this.generatingPdf;
		}
	}

	setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		if (this.form.sections.pagamento[0]!.tipopagsaldoId) {
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if (tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(9).fill(1).map((x, i) => i);
			} else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(8).fill(1).map((x, i) => i);
			}
		}

		this.scrollTop();
	}

	checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	validCannaFumaria(): boolean {
		if (this.form.sections.accessori.accessotetto == null) {
			return false;
		}

		if (this.form.sections.accessori.accessotetto == false && !this.form.sections.accessori.cestaidr && !this.form.sections.accessori.ponteggio) {
			return false;
		}

		if (this.form.sections.accessori.valvolatermo == null) {
			return false;
		}
		return true;
	}

	getOrdine(): void {
		this.orderService.getSingleOrder(this.form.idOrder).subscribe(res => {
			this.form.anagrafica.infoContratto.consulente = res.venditoreLogin;
			this.form.anagrafica.agente = res.venditoreLogin;
		}, error => {
			console.error('Error => ', error);
		});
	}

	scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

	async saveModule(value): Promise<void> {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;

			if (this.anagrafica.id) {
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					}
					);
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			} else {
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
						this.setStep(this.currentStep + 1);
					}
					);
				}, error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				});
			}
		} else if (this.currentStep === 1) {
			this.confirmCaldaia = value;

			if (!this.confirmCaldaia && this.moreThanOneCaldaia()) {
				this.openConfirmCaldaia(false);
				return;
			} else {
				this.confirmCaldaia = true;
			}
		
			if (this.confirmCaldaia) {
				// Step salvataggio caldaie
				const savingCaldaie = [];
				this.form.sections.caldaie.forEach(r => {
					if (r.checked) {
						savingCaldaie.push(r);
					}
					if (!r.checked && r.id !== undefined) {
						this.removeCaldaia(r.id);
					}
				});
		
				try {
					let allSuccess = true;
		
					// Salvataggio delle caldaie
					for (const s of savingCaldaie) {
						delete s.checked;
						delete s.coloreDescrizione;
						try {
							if (s.id !== undefined) {
								await this.updateCaldaia(s);
							} else {
								await this.saveCaldaia(s); 
							}
						} catch (error) {
							console.error('Errore durante il salvataggio della caldaia:', error);
							allSuccess = false;
							break; 
						}
					}
		
					if (allSuccess && this.hasValues(this.form.sections.dettaglioCaldaia)) {
						this.form.sections.dettaglioCaldaia.ordineId = this.form.idOrder;
		
						try {
							if (this.form.sections.dettaglioCaldaia.id) {
								await this.updateDettaglio(this.form.sections.dettaglioCaldaia);
							} else {
								await this.saveDettaglio(this.form.sections.dettaglioCaldaia); 
							}
						} catch (error) {
							console.error('Errore durante il salvataggio del dettaglio caldaia:', error);
							allSuccess = false;
						}
					}
		
					if (allSuccess) {
						this.setStep(this.currentStep + 1);
					} else {
						console.error('Processo fallito: non si avanza allo step successivo.');
					}
				} catch (error) {
					console.error('Errore inatteso, processo interrotto:', error);
				}
			} else {
				// STOP | non avanzo lo step | popup rimane visualizzato step 1
			}
		
			try {
				await this.orderService.update(JSON.stringify(this.form.ordine)).toPromise(); 
			} catch (error) {
				console.error('Errore durante l\'aggiornamento dell\'ordine:', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			}
		} else if (this.currentStep === 2) {

			try {
				let allSuccess = true;

				// Step salvataggio disegno caldaia
				if (this.hasValues(this.form.sections.disegnoCaldaia)) {
					this.form.sections.disegnoCaldaia.ordineId = this.form.idOrder;
					try {
						if (this.form.sections.disegnoCaldaia.id) {
							await this.updateDisegno(this.form.sections.disegnoCaldaia);
						} else {
							await this.saveDisegno(this.form.sections.disegnoCaldaia);
						}
					} catch (error) {
						console.error('Errore nel salvataggio del disegno caldaia:', error);
						allSuccess = false;
					}
				}

				// Step salvataggio dati abitazione
				if (allSuccess && this.hasValues(this.form.sections.abitazione)) {
					this.form.sections.abitazione.ordineId = this.form.idOrder;
					try {
						if (this.form.sections.abitazione.id) {
							await this.abitazioneService.update(JSON.stringify(this.form.sections.abitazione)).toPromise();
						} else {
							await this.abitazioneService.save(JSON.stringify(this.form.sections.abitazione)).toPromise();
						}
					} catch (error) {
						console.error('Errore nel salvataggio dei dati abitazione:', error);
						allSuccess = false;
					}
				}

				// Se tutte le operazioni hanno avuto successo, passa allo step successivo
				if (allSuccess) {
					this.setStep(this.currentStep + 1);
				} else {
					console.error('Processo fallito, step non incrementato.');
				}
			} catch (error) {
				console.error('Errore imprevisto durante il salvataggio:', error);
			}
		} else if (this.currentStep === 3) {
			// Step salvataggio accessori caldaia
			if (this.hasValues(this.form.sections.accessori)) {
				this.form.sections.accessori.ordineId = this.form.idOrder;
				if (this.form.sections.accessori.id) {
					this.updateAccessori(this.form.sections.accessori);
				} else {
					this.saveAccessori(this.form.sections.accessori);
				}
			} else {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 4) {
			//Step salvataggio omaggioDTO

			let allSuccess = true;

			for (let i = 0; i < this.form.sections.omaggio.length; i++) {
				const omaggio: OmaggioDto = this.form.sections.omaggio[i];
				omaggio.ordineId = this.form.idOrder;

				try {
					if (omaggio.id === undefined || omaggio.id === null) {
						// Save new omaggio
						await this.omaggioService.save(JSON.stringify(omaggio)).toPromise();
					} else {
						// Update existing omaggio
						await this.omaggioService.update(JSON.stringify(omaggio)).toPromise();
					}
				} catch (error) {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					allSuccess = false;
					break;
				}
			}

			if (allSuccess) {
				this.setStep(this.currentStep + 1);
			}
		} else if (this.currentStep === 5) {
			// Step salvataggio pagamento
			if (this.hasValues(this.form.sections.pagamento[0])) {
				this.form.sections.pagamento[0].ordineId = this.form.idOrder;
				const payment = this.form.sections.pagamento[0];
				if (payment.id === undefined || payment.id === null) {
					this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				} else {
					this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						this.setStep(this.currentStep + 1);
						// se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
						if (res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
							this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
								allegati.forEach((allegato: any) => {
									if (allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
										this.allegatoService.delete(allegato.id).subscribe(() => {
										}, error => {
											console.error('Error => ', error);
											this.formController.openSnackBar(constants.ERROR_GENERIC_API);
										});
									}
								})
							})
						}
					}, error => {
						console.error('Error => ', error);
						this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					});
				}
			}
		} else if (this.currentStep === 6) {
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe(() => {
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 7) {
			this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
				this.ordine.clienteId = res.id;
				this.ordine.cognome = res.cognome;
				this.ordine.nome = res.nome;
				this.setStep(this.currentStep + 1);
			}, error => {
				console.error('Error => ', error);
				this.formController.openSnackBar(constants.ERROR_GENERIC_API);
			});
		} else if (this.currentStep === 8 && this.form.sections.finanziamentoSections.isAllegatoSaved) {
			// SOLO NEXT-STEP | PARTE FINANZIAMENTO
			this.setStep(this.currentStep + 1);
		}
	}

	moreThanOneCaldaia(): boolean {
		let count = 0;
		this.form.sections.caldaie.forEach(r => {
			if (r.checked) {
				count += 1;
			}
		});
		if (count > 1) {
			return true;
		} else {
			return false;
		}
	}

	openConfirmCaldaia(value): void {
		this.confirmCaldaia = value;
		jQuery('#confirmModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#confirmModal').modal('toggle');
		if (this.confirmCaldaia) {
			this.saveModule(true);
		}
	}

	saveCaldaia(caldaia): Promise<any> {
		return new Promise((resolve, reject) => {
			this.caldaiaService.save(JSON.stringify(caldaia)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	updateCaldaia(caldaia): Promise<any> {
		return new Promise((resolve, reject) => {
			this.caldaiaService.update(JSON.stringify(caldaia)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	saveDettaglio(dettaglio): Promise<any> {
		return new Promise((resolve, reject) => {
			this.caldaiaService.saveDettaglio(JSON.stringify(dettaglio)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	updateDettaglio(dettaglio): Promise<any> {
		return new Promise((resolve, reject) => {
			this.caldaiaService.updateDettaglio(JSON.stringify(dettaglio)).subscribe(
				res => resolve(res),
				error => {
					console.error('Error => ', error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
					reject(error);
				}
			);
		});
	}

	saveDisegno(disegno): void {
		this.caldaiaService.saveDisegno(JSON.stringify(disegno)).subscribe(res => {
		}, error => {
			console.error('Error => ', error);
			this.formController.openSnackBar(constants.ERROR_GENERIC_API);
		});
	}

	updateDisegno(disegno): void {
		this.caldaiaService.updateDisegno(JSON.stringify(disegno)).subscribe(res => {
		}, error => {
			console.error('Error => ', error);
			this.formController.openSnackBar(constants.ERROR_GENERIC_API);
		});
	}

	saveAccessori(accessori): void {
		this.caldaiaService.saveAccessori(JSON.stringify(accessori)).subscribe(res => {
			this.setStep(this.currentStep + 1);
		}, error => {
			console.error('Error => ', error);
			this.formController.openSnackBar(constants.ERROR_GENERIC_API);
		});
	}

	updateAccessori(accessori): void {
		this.caldaiaService.updateAccessori(JSON.stringify(accessori)).subscribe(res => {
			this.setStep(this.currentStep + 1);
		}, error => {
			console.error('Error => ', error);
			this.formController.openSnackBar(constants.ERROR_GENERIC_API);
		});
	}

	removeCaldaia(id): Promise<any> {
		return new Promise((resolve, reject) => {
			this.caldaiaService.delete(JSON.stringify(id)).subscribe(
				res => {
					resolve(res);
				},
				error => {
					console.error('Error => ', error);
					reject(error);
					this.formController.openSnackBar(constants.ERROR_GENERIC_API);
				}
			);
		});
	}


	togglePrintPreview(show: boolean): void {
		this.showModalPrint = show;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			this.ordine = res;
			jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#printModal').modal('toggle');
		})
	}

	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	validCaldaie(): boolean {
		let valid = true;
		if (this.form.sections.caldaie.length > 0) {
			for (let i = 0; i < this.form.sections.caldaie.length; i++) {
				if (valid) {
					if (this.form.sections.caldaie[i].checked && !this.form.sections.caldaie[i].tipologiaId) {
						valid = false;
					} else if (this.form.sections.caldaie[i].checked && this.form.sections.caldaie[i].tipologiaId && this.form.sections.caldaie[i].modcaldcondecowdId && !this.form.sections.caldaie[i].coloreId) {
						valid = false;
					} else {
						valid = true;
					}
				}
			}
		}
		return valid;
	}

	validDatiAbitazione(): boolean {
		if (
			this.form.sections.abitazione.pianointerv === undefined ||
			this.form.sections.abitazione.pianointerv == null ||
			this.form.sections.abitazione.pianistabile === undefined ||
			this.form.sections.abitazione.pianistabile == null ||
			this.form.sections.abitazione.mq === undefined ||
			this.form.sections.abitazione.mq == null ||
			this.form.sections.abitazione.mq < 1 ||
			!this.form.sections.abitazione.cannafumariaId
		) {
			return false;
		} else {
			return true;
		}
	}

	validDettagliInstallazione(): boolean {
		const accessori = this.form.sections.accessori;
	
		// Controllo dei campi obbligatori
		if (!accessori.installazioneId ||
			!accessori.uscitafumiId ||
			!accessori.prelievoariaId ||
			!accessori.scaricocondId) {
			return false;
		}
	
		// Controllo aggiuntivo per `necessarioForareTop` se `scaricocondId` è `DESC_SIFONE_LAVELLO`
		const isSifoneLavello = this.formController.checkSifoneLavello(this.form, this.trascodifiche);
		if (isSifoneLavello && accessori.necessarioForareTop == null) {
			return false;
		}
	
		return true;
	}

	validDisegnoCaldaia(): boolean {
		if (this.form.sections.disegnoCaldaia.tipoId) {
			if (this.form.sections.disegnoCaldaia.tipoId === 1341) {
				delete this.form.sections.disegnoCaldaia.altezza;
				delete this.form.sections.disegnoCaldaia.larghezza;
				delete this.form.sections.disegnoCaldaia.profondita;
				return true;
			} else {
				if (this.form.sections.disegnoCaldaia.altezza === undefined ||
					this.form.sections.disegnoCaldaia.altezza == null ||
					this.form.sections.disegnoCaldaia.altezza < 0.1 ||
					this.form.sections.disegnoCaldaia.larghezza === undefined ||
					this.form.sections.disegnoCaldaia.larghezza == null ||
					this.form.sections.disegnoCaldaia.larghezza < 0.1 ||
					this.form.sections.disegnoCaldaia.profondita === undefined ||
					this.form.sections.disegnoCaldaia.profondita == null ||
					this.form.sections.disegnoCaldaia.profondita < 0.1
				) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			return false;
		}
	}
		
	validAccessori(): boolean {
		if (this.form.sections.accessori.cronowifi && !this.form.sections.accessori.numcronowifi) {
			return false;
		}
		if (this.form.sections.accessori.cronostand && !this.form.sections.accessori.numcronostand) {
			return false;
		}
		return true;
	}
		
	closeSignPdf(){
		jQuery('#signModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#signModal').modal('toggle');
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe(res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

	reload(value: boolean) {
		this.openSign = value;
	}
}