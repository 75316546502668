<div class="h-100">
    <div class="my-auto">        
        <div style="padding:80px 20px 20px;">
            <h4 class="mb-5">Benvenuto {{currentUser.firstName}} {{currentUser.lastName}}</h4>
            <div class="singleButtonDash" matRipple routerLink="/order-list" *ngIf="userService.returnUserAut(constants.TYPE_ROLE_SELLER)">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista ordini
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/order-frozen-list" *ngIf="userService.returnUserAut(constants.TYPE_ROLE_SELLER)">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista ordini congelati
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/service-act-order-list" *ngIf="userService.returnUserAut(constants.TYPE_ROLE_SELLER)">
                <div>
                    <mat-icon>add_shopping_cart</mat-icon>
                </div>
                <div>
                    Attivazione servizi
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/new-order" *ngIf="userService.returnUserAut(constants.TYPE_ROLE_SELLER)">
                <div>
                    <mat-icon>edit</mat-icon>
                </div>
                <div>
                    Nuovo ordine
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/new-order" *ngIf="userService.returnUserAut(constants.TYPE_ROLE_TECH)">
                <div>
                    <mat-icon>list</mat-icon>
                </div>
                <div>
                    Lista ordini
                </div>
            </div>
            <div class="singleButtonDash" matRipple routerLink="/search-order" *ngIf="userService.returnUserAut(constants.TYPE_ROLE_CUSTOMER_CARE) || userService.returnUserAut(constants.TYPE_ROLE_SUPPORT)">
                <div>
                    <mat-icon>search</mat-icon>
                </div>
                <div>
                    Ricerca ordini
                </div>
            </div>  
            <div class="singleButtonDash" matRipple *ngIf="userService.returnUserAut(constants.TYPE_ROLE_SUPPORT)" routerLink="/impersonate_user">
                <div>
                    <mat-icon>supervisor_account</mat-icon>
                </div>
                <div>
                    Impersonifica
                </div>
            </div>         
        </div>
    </div>
</div>



