import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { constants } from '../modules/classes/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpService{

  basePath: string;
  key: any;
  token: string;
  isexpired: boolean;
  decodedToken: any;
  expirationDate: any;
  helper = new JwtHelperService();
  
  constructor(
      public router: Router,
      public http: HttpClient,
      private httpService: HttpService,
  ) {
    super(router, http);
    this.basePath = httpService.setApiurl();
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  // Handle API errors
  handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Errore:', error.error.message);
    } else if(error.error.message === 'error.userexists') {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.message}`);
		return throwError('Attenzione, utente gia\' registrato'); 
    }else if(error.error.message === 'error.http.500') {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.message}`);
		return throwError('Attenzione, utente non presente nei nostri sistemi'); 
    }else if(error.error.message === "error.http.401"){
		if(error.error.detail === "Credenziali non valide"){
			return throwError(error.error.detail);
		}else{
			return throwError('Attenzione, utente non ancora attivato');
		}
			 
	}
    // return an observable with a user-facing error message
    return throwError(
        error.error.detail);
  }

  // Verify user credentials on server to get token
  loginForm(data): Observable<any> {
    return this.http
     .post<any>(this.basePath + 'authenticate', data, this.httpOptions)
     .pipe(
         retry(0),
         catchError(this.handleError)
     );
  }

  forgotForm(data): Observable<any> {
    return this.http
               .post<any>(this.basePath + 'account/reset-password-webapp/init', data, this.httpOptions)
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  sendNewPassword(data): Observable<any> {
    return this.http
               .post<any>(this.basePath + 'account/reset-password-webapp/finish', data, this.httpOptions)
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  sendActivation(data): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = new HttpParams().set('key', data);
    return this.http.get(this.basePath + 'activateWeb', { headers, params });
  }

  setUser(resp): any{
    // localStorage.setItem('or_profile', JSON.stringify(resp.profile));
    localStorage.setItem('or_access_token', resp.id_token);
  }

  setProfile(res): void{
    localStorage.setItem('or_profile', JSON.stringify(res));
    if (!this.checkRole(res)) {
      localStorage.removeItem('or_access_token');
      localStorage.removeItem('or_profile');
      this.router.navigate(['/login']);
    } else {
        if(this.returnUserRole() === constants.TYPE_ROLE_TECH){
          this.router.navigate(['/tech_dashboard']);
        }else{
          this.router.navigate(['/dashboard']);
        }
    }
  }

  checkRole(obj): boolean{
    return !!obj.authorities.includes(constants.TYPE_ROLE_SELLER) || !!obj.authorities.includes(constants.TYPE_ROLE_TECH) || !!obj.authorities.includes(constants.TYPE_ROLE_SUPPORT) || !!obj.authorities.includes(constants.TYPE_ROLE_CUSTOMER_CARE);
  }

  // Checking if token is set
  isLoggedIn(): boolean {
    if (!localStorage.getItem('or_access_token')) {
      return false;
    } else {
      this.token = localStorage.getItem('or_access_token');
      this.decodedToken = this.helper.decodeToken(this.token);
      this.expirationDate = this.helper.getTokenExpirationDate(this.token);
      this.isexpired = this.helper.isTokenExpired(this.token);
      return !this.isexpired;
    }
  }
  
  returnUserRole(): string {
    if (!localStorage.getItem('or_access_token')) {
      return '';
    } else {
      this.token = localStorage.getItem('or_access_token');
      this.decodedToken = this.helper.decodeToken(this.token);
      if(this.decodedToken.auth){
        return this.decodedToken.auth;
      }else{
        return ''
      }
    }
  }

  // After clearing localStorage redirect to login screen
  logout(): void {
    localStorage.removeItem('or_access_token');
    localStorage.removeItem('or_profile');
    localStorage.removeItem('id_gdl');
    this.router.navigate(['/login']);
  }

  registrationForm(data): Observable<any> {
    return this.http
     .post<any>(this.basePath + 'registerWebApp', data, this.httpOptions)
     .pipe(
         retry(0),
         catchError(this.handleError)
     );
  }

  loginAs(user: any){
    return this.http.get<any>(this.basePath + 'authenticateImpersonation?ssoId=' + user.login, this.setHttpOptions())
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  switchUser(resp: any): any {
    localStorage.setItem('or_access_token', resp.id_token);
  }
}
