<div class="pt-5 text-center mb-4">
</div>

<div class="text-center mb-4">
	<h4>ORDINI DISPONIBILI</h4>
</div>


<div class="with-border" *ngIf="contracts">
	<div class="padding15">
		<mat-form-field class="" appearance="standard"> <mat-label>Cerca
		ordine</mat-label> <input matInput (keyup)="applyFilter($event)" #input> </mat-form-field>
	</div>
	<div class="tableCont">
		<mat-progress-bar *ngIf="loading" mode="buffer"></mat-progress-bar>
		<table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="dataordine" matSortDirection="desc">

			<ng-container matColumnDef="idOrdine">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Id Ordine</th>
				<td mat-cell *matCellDef="let element">
					{{element.idordine}}</td>
			</ng-container>

			<ng-container matColumnDef="brandDescrizione">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
				<td mat-cell *matCellDef="let element">
					{{element.brandDescrizione}}</td>
			</ng-container>
		
			<ng-container matColumnDef="dataordine">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
				<td mat-cell *matCellDef="let element">{{element.dataordine |
						date: 'dd/MM/yyyy'}}</td>
			</ng-container>

			<ng-container matColumnDef="statopraticaDescrizione">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Stato
					pratica</th>
				<td mat-cell *matCellDef="let element">
					{{element.statopraticaDescrizione}}
				</td>
			</ng-container>

			<ng-container matColumnDef="nomeProdotto" sticky>
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Prodotto</th>
				<td mat-cell *matCellDef="let element">
				 {{element.nomeProdotto}}
				</td>
			</ng-container>

			<ng-container matColumnDef="clienteCf">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					Nominativo Cliente</th>
				<td mat-cell *matCellDef="let element">{{element.cognome}}</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef class="lastCl actionCss"></th>
				<td mat-cell *matCellDef="let element" class="lastCl actionCss">
					<button mat-icon-button [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
					  </button>
					
					  <mat-menu #menu="matMenu">
						<button mat-menu-item
						  (click)="goToOrder(element.prodottoCommessa, element.clienteId, element.prodottoId, element.id)">
						  <mat-icon>open_in_new</mat-icon> Compila
						</button>
					
						<button mat-menu-item
						  *ngIf="!disabledByStatoPratica(element.statopraticaCodice)"
						  (click)="createOrder(element.id, element.gdlid)">
						  <mat-icon>note_add</mat-icon> Nuovo prodotto
						</button>
					  </mat-menu>
					<!--<button color="primary" class="btnMedium mr-2"
							  mat-flat-button
							  (click)="goToOrder(element.prodottoCommessa,element.clienteId,element.prodottoId,element.id)">
							<span class="material-icons">open_in_new</span> Compila
					</button>
					<button class="btnMedium greenBtn" mat-flat-button
						  (click)="createOrder(element.id,element.gdlid)"
						  [disabled]="disabledByStatoPratica(element.statopraticaCodice)">
						<span class="material-icons">note_add</span> Nuovo prodotto
					</button>-->
				</td>
			</ng-container>	

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>

	<mat-paginator [pageSizeOptions]="[10, 25, 100]"
		aria-label="Select page"></mat-paginator>
</div>
