import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  // Verify user credentials on server to get token
  getUser(data): Observable<any> {
    return this.http
     .get<any>(this.apiUrl + 'accountByUser/' + data, this.setHttpOptions())
     .pipe(
         retry(0),
         catchError(this.handleError)
     );
  }

  getAllUser(params): Observable<any> {
    return this.http
     .get<any>(this.apiUrl + 'users' + params, this.setHttpOptions())
     .pipe(
         retry(0),
         catchError(this.handleError)
     );
  }
  
  forgotForm(data): Observable<any> {
    return this.http
               .post<any>(this.apiUrl + 'account/reset-password/init', data, this.setHttpOptions())
               .pipe(
                   retry(0),
                   catchError(this.handleError)
               );
  }

  returnUserAut(role): boolean {
      const profile = JSON.parse(localStorage.getItem('or_profile'));
      const authorities = [...profile.authorities];
    return !!authorities.includes(role);
  }
}
