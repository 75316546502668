import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { BrandService } from '../../../../../services/brand/brand.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { DisegnoTecnicoService } from '../../../../../services/disegnoTecnico/disegno-tecnico.service';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { AccessoriService } from '../../../bagni-italiani/main-bagni/services/accessori/accessori.service';
import { AnagraficaDTO, AnagraficaForm } from '../../../../classes/anagrafica';
import { DisegnoTecnico } from '../../../../classes/disegno-tecnico';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { Trascodifica } from '../../../../classes/trascodifica';
import { DatitecnicivascaDTO } from '../../../../classes/remail/datitecnicivasca-dto';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import { AccessoriDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { CristalBoxService } from '../../services/cristalbox.service';
import { NuovidatitecniciDTO } from '../../../../classes/remail/nuovidatitecnici-dto';
import { BoxdocciaDTO } from '../../../../classes/remail/boxdoccia-dto';
import { BoxdocciaService } from '../../../bagni-italiani/main-bagni/services/boxdoccia/boxdoccia.service';
import { OpereIdraulicheDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { OpereIdraulicheService } from '../../../bagni-italiani/main-bagni/services/opereIdrauliche/opereIdrauliche.service';
import { GresDto } from '../../../../classes/remail/gres-dto';
import { MiscelatoreDto } from '../../../../classes/remail/miscelatore-dto';
import { VascaDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
import { Ordine } from '../../../../classes/ordine';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import * as $ from 'jquery';

@Component({
	selector: 'app-cristalbox-vasca-in-doccia',
	templateUrl: './vasca-in-doccia.component.html',
	styleUrls: ['./vasca-in-doccia.component.scss']
})
export class CristalBoxVascaInDocciaComponent implements OnInit, DoCheck {

	openSign: boolean = false;
	generatingPdf: boolean = false;
	validAnagrafica: boolean = false;
	isFinanziamentoPayment: boolean = false;
	showModalPrint: boolean = false;

	currentStep: number = 0;
	currentPrinting: number = 0;

	totalSteps: number[];

	trascodifiche: Trascodifica[];
	listFinanziarie: Trascodifica[];
	attivitaLavList: Trascodifica[] = [];

	originalForm: any;
	idCliente: number;
	ordine: Ordine;
	anagrafica: AnagraficaDTO;

	error: any;
	obj1: any;
	obj2: any;

	form: any;

	hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

	finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/cristalbox.jpg', product: 'vasca in doccia', brand: 'Cristalbox'}
		 
	constructor(
		public formController: FormControllerService,
		private route: ActivatedRoute,
		private brandService: BrandService,
		private anagraficaService: AnagraficaService,
		private snackbar: MatSnackBar,
		private paymentService: PaymentService,
		private disegnoService: DisegnoTecnicoService,
		private omaggioService: OmaggioService,
		private cristalBoxService: CristalBoxService,
		private accessoriService: AccessoriService,
		private boxdocciaService: BoxdocciaService,
		private opIdraulicheService: OpereIdraulicheService,
		private orderService: OrdersService,
		private optionService: OptionsAvailableService,
		private allegatoService: AllegatoService
	) {
		this.showModalPrint = false;
		this.validAnagrafica = false;
		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('id')),
			anagrafica: new AnagraficaForm(),
			sections: {
				datitecnicivasca: new DatitecnicivascaDTO(),
				nuovidatitecnici: new NuovidatitecniciDTO(),
				boxdoccia: [new BoxdocciaDTO()],
				piattoDoccia: new OpereIdraulicheDTO(),
				gres: new GresDto(),
				miscelatore: new MiscelatoreDto(),
				vasca: new VascaDTO(),
				disegno: [new DisegnoTecnico()],
				omaggio: [new OmaggioDto()],
				accessorio: [new AccessoriDTO],
				accessorioToDelete: [new AccessoriDTO],
				pagamento: [new PagamentoDTO()],
				finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
				optionsAvailable: []
			},
			ordine: null,

		};

		this.totalSteps = Array(10).fill(1).map((x, i) => i);  // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
	}

	getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);
			if (res.clienteId !== undefined && res.clienteId !== null) {
				localStorage.setItem('id_client', res.clienteId);
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}
			localStorage.setItem('id_order', res.idordine);
		});
		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
			this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
		});
		this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
	}

	checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}

	ngOnInit(): void {
		this.originalForm = JSON.parse(JSON.stringify(this.form));
		if (localStorage.getItem('id_client') !== null) {
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.getOrder(this.form.idOrder);
	}

	setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(10).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(9).fill(1).map((x, i) => i);
			}
		}

		this.scrollTop();
	}

	scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

	openSnackBar(text): void {
		this.snackbar.open(text, 'Ok', {
			duration: 4000
		});
	}

	saveModule(): void {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if (this.anagrafica.id) {
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe();
				}, error => {
					console.error('Error => ', error);
					this.openSnackBar('Errore in fase di salvataggio del modulo');
				});
			} else {
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe();
				}, error => {
					console.error('Error => ', error);
					this.openSnackBar('Errore in fase di salvataggio del modulo');
				});
			}
		} else if (this.currentStep === 1) {
			//Step salvataggio promo	
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
			if (this.hasValues(this.form.sections.datitecnicivasca)) {
				this.form.sections.datitecnicivasca.ordineId = this.form.idOrder;
				const datiVasca = this.form.sections.datitecnicivasca;
				if (datiVasca.id === undefined || datiVasca.id === null) {
					this.cristalBoxService.saveDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
				} else {
					this.cristalBoxService.updateDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
				}
			}
		} else if (this.currentStep === 2) {
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
			if (this.hasValues(this.form.sections.datitecnicivasca)) {
				this.form.sections.nuovidatitecnici.ordineId = this.form.idOrder;
				const datiVasca = this.form.sections.nuovidatitecnici;
				if (datiVasca.id === undefined || datiVasca.id === null) {
					this.cristalBoxService.saveNuoviDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
				} else {
					this.cristalBoxService.updateNuoviDatiTecVasca(JSON.stringify(datiVasca)).subscribe();
				}
			}

			let saveBoxDoccia: boolean;
			let savePiattoDoccia: boolean;
			//Salvo o aggiorno l'opzione box doccia per l'ordine
			for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
				let option: OptionsAvailable = this.form.sections.optionsAvailable[i];

				if (saveBoxDoccia == undefined) {
					saveBoxDoccia = this.searchBool(constants.TYPE_NUOVO_BOX_DOCCIA, option)
				}
				if (savePiattoDoccia == undefined) {
					savePiattoDoccia = this.searchBool(constants.TYPE_NUOVO_PIATTO_DOCCIA, option)
				}
			}

			for (let i = 0; i < this.form.sections.boxdoccia.length; i++) {
				const box = this.form.sections.boxdoccia[i];
				if (saveBoxDoccia !== undefined) {
					if (!saveBoxDoccia) {
						//se non è stato selezionato il box doccia elimino i vecchi box precedentemente aggiunti al contratto
						this.boxdocciaService.delete(box.id).subscribe();
					} else {
						if (this.hasValues(box)) {
							box.ordineId = this.form.idOrder;
							if (box.id === undefined || box.id === null) {
								this.boxdocciaService.save(JSON.stringify(box)).subscribe();
							} else {
								this.boxdocciaService.update(JSON.stringify(box)).subscribe();
							}
						}
					}
				}
			}

			if (savePiattoDoccia != undefined) {
				if (!savePiattoDoccia) {
					this.form.sections.piattoDoccia.colorepiattolargId = null;
					this.form.sections.piattoDoccia.colorepiattolargDescrizione = null;
					this.form.sections.piattoDoccia.misurapiattolargId = null;
					this.form.sections.piattoDoccia.misurapiattolargDescrizione = null;
					this.form.sections.piattoDoccia.misurapiattostrId = null;
					this.form.sections.piattoDoccia.misurapiattostrDescrizione = null;
				}
				if (this.hasValues(this.form.sections.piattoDoccia)) {
					this.form.sections.piattoDoccia.ordineId = this.form.idOrder;
					if (this.form.sections.piattoDoccia.id === undefined || this.form.sections.piattoDoccia.id === null) {
						this.opIdraulicheService.save(JSON.stringify(this.form.sections.piattoDoccia)).subscribe((res: any) => {
							this.form.sections.piattoDoccia = res;
						});
					} else {
						this.opIdraulicheService.update(JSON.stringify(this.form.sections.piattoDoccia)).subscribe((res: any) => {
							this.form.sections.piattoDoccia = res;
						});
					}
				}
			}

			if (this.hasValues(this.form.sections.gres)) {
				this.form.sections.gres.ordineId = this.form.idOrder;
				if (this.form.sections.gres.id === undefined || this.form.sections.gres.id === null) {
					this.cristalBoxService.save('gres', JSON.stringify(this.form.sections.gres)).subscribe();
				} else {
					this.cristalBoxService.update('gres', JSON.stringify(this.form.sections.gres)).subscribe();
				}
			}

			if (this.hasValues(this.form.sections.miscelatore)) {
				this.form.sections.miscelatore.ordineId = this.form.idOrder;
				if (this.form.sections.miscelatore.id === undefined || this.form.sections.miscelatore.id === null) {
					this.cristalBoxService.save('miscelatores', JSON.stringify(this.form.sections.miscelatore)).subscribe();
				} else {
					this.cristalBoxService.update('miscelatores', JSON.stringify(this.form.sections.miscelatore)).subscribe();
				}
			}
		} else if (this.currentStep === 3) {
			let saveVasca: boolean;
			//Salvo o aggiorno l'opzione vasca per l'ordine
			for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
				let option: OptionsAvailable = this.form.sections.optionsAvailable[i];

				if (saveVasca == undefined) {
					saveVasca = this.searchBool(constants.TYPE_VASCA, option)
				}
			}

			//VASCA 
			if (this.hasValues(this.form.sections.vasca)) {
				this.form.sections.vasca.ordineId = this.form.idOrder;
				if (!saveVasca) {
					if (this.form.sections.vasca.id !== undefined || this.form.sections.vasca.id !== null) {
						this.cristalBoxService.delete(this.form.sections.vasca.id, 'vascas').subscribe();
					}
				} else {
					if (this.form.sections.vasca.id === undefined || this.form.sections.vasca.id === null) {
						this.cristalBoxService.save('vascas', JSON.stringify(this.form.sections.vasca)).subscribe();
					} else {
						this.cristalBoxService.update('vascas', JSON.stringify(this.form.sections.vasca)).subscribe();
					}
				}
			}
		} else if (this.currentStep === 4) {
			this.form.sections.disegno[0].ordineId = this.form.idOrder;
			if (this.form.sections.disegno[0].id === undefined) {
				this.disegnoService.save(JSON.stringify(this.form.sections.disegno[0])).subscribe();
			} else {
				this.disegnoService.update(JSON.stringify(this.form.sections.disegno[0])).subscribe();
			}
			//Step salvataggio omaggioDTO
			for (var i = 0; i < this.form.sections.omaggio.length; i++) {
				var omaggio: OmaggioDto = this.form.sections.omaggio[i];
				omaggio.ordineId = this.form.idOrder;
				if (omaggio.id === undefined || omaggio.id === null) {
					this.omaggioService.save(JSON.stringify(omaggio)).subscribe();
				} else {
					this.omaggioService.update(JSON.stringify(omaggio)).subscribe();
				}
			}

			if (this.hasValues(this.form.sections.piattoDoccia)) {
				this.form.sections.piattoDoccia.ordineId = this.form.idOrder;
				if (this.form.sections.piattoDoccia.id === undefined || this.form.sections.piattoDoccia.id === null) {
					this.opIdraulicheService.save(JSON.stringify(this.form.sections.piattoDoccia)).subscribe((res: any) => {
						this.form.sections.piattoDoccia = res;
					});
				} else {
					this.opIdraulicheService.update(JSON.stringify(this.form.sections.piattoDoccia)).subscribe((res: any) => {
						this.form.sections.piattoDoccia = res;
					});
				}
			}
		} else if (this.currentStep === 5) {
			//step salvataggio accessori
			for (var i = 0; i < this.form.sections.accessorio.length; i++) {
				var acc: AccessoriDTO = this.form.sections.accessorio[i];
				acc.ordineId = this.form.idOrder;
				if (acc.tipologiaId !== null && acc.quantita > 0) {
					if (acc.id === undefined || acc.id === null) {
						this.accessoriService.save(acc).subscribe();
					} else {
						this.accessoriService.update(acc).subscribe();
					}
				}
			}

			//Step delete unchecked accessori
			for (var i = 0; i < this.form.sections.accessorioToDelete.length; i++) {
				if (this.form.sections.accessorioToDelete[i].id !== undefined && this.form.sections.accessorioToDelete[i].id !== null) {
					this.accessoriService.delete(this.form.sections.accessorioToDelete[i].id).subscribe();
				}

			}
		} else if (this.currentStep === 6) {
			// Step salvataggio pagamento
			if (this.hasValues(this.form.sections.pagamento[0])) {
				this.form.sections.pagamento[0].ordineId = this.form.idOrder;
				const payment = this.form.sections.pagamento[0];
				if (payment.id === undefined || payment.id === null) {
					this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
					});
				} else {
					this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
						// se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
						if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
							this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
							  allegati.forEach((allegato:any) => {
								if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
								  this.allegatoService.delete(allegato.id).subscribe();
								}
							  })
							})
						  }
					});
				}
			}
		} else if (this.currentStep === 7) {
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
		}
		else if (this.currentStep === 8) {
			this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
				this.ordine.clienteId = res.id;
				this.ordine.cognome = res.cognome;
				this.ordine.nome = res.nome;
			}, error => {
				console.error('Error => ', error);
				this.openSnackBar('Errore in fase di salvataggio del modulo');
			});
		}
		this.setStep(this.currentStep + 1);
	}

	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	validMurettoSx(): boolean {
		if ((this.form.sections.datitecnicivasca.lungmurosx ||
			this.form.sections.datitecnicivasca.largmurosx ||
			this.form.sections.datitecnicivasca.altmurosx) &&
			(this.form.sections.datitecnicivasca.eliminamurosx === null ||
				this.form.sections.datitecnicivasca.eliminamurosx === undefined ||
				this.form.sections.datitecnicivasca.zoppo === null ||
				this.form.sections.datitecnicivasca.zoppo === undefined)
		) {
			return false;
		} else {
			return true;
		}
	}

	validMurettoDx(): boolean {
		if ((this.form.sections.datitecnicivasca.lungmurodx ||
			this.form.sections.datitecnicivasca.largmurodx ||
			this.form.sections.datitecnicivasca.altmurodx) &&
			(this.form.sections.datitecnicivasca.eliminamurodx === null ||
				this.form.sections.datitecnicivasca.eliminamurodx === undefined ||
				this.form.sections.datitecnicivasca.zoppomurodx === null ||
				this.form.sections.datitecnicivasca.zoppomurodx === undefined)
		) {
			return false;
		} else {
			return true;
		}
	}

	validMurettoDietro(): boolean {
		if ((this.form.sections.datitecnicivasca.lungmurodietro ||
			this.form.sections.datitecnicivasca.largmurodietro ||
			this.form.sections.datitecnicivasca.altmurodietro) &&
			(this.form.sections.datitecnicivasca.zoppomurodietro === null ||
				this.form.sections.datitecnicivasca.zoppomurodietro === undefined)
		) {
			return false;
		} else {
			return true;
		}
	}

	validMurettoDavanti(): boolean {
		if ((this.form.sections.datitecnicivasca.lungmuroavanti ||
			this.form.sections.datitecnicivasca.largmuroavanti ||
			this.form.sections.datitecnicivasca.altmuroavanti) &&
			(this.form.sections.datitecnicivasca.zoppomuroavanti === null ||
				this.form.sections.datitecnicivasca.zoppomuroavanti === undefined)
		) {
			return false;
		} else {
			return true;
		}
	}

	stepController(): boolean {
		let disabled = false;
		if ((!this.validAnagrafica && this.currentStep === 0) || (!this.validAnagrafica && this.currentStep === 8)) {
			disabled = true;
		}
		if (!this.formController.validAccessori(this.form) && this.currentStep === 5) {
			disabled = true;
		}
		if (!this.validPiattoPietra() ||  !this.validNuovoBoxDoccia(this.form) && this.currentStep === 2) {
			disabled = true;
		}
		
		if(!this.form.ordine?.promoId && this.currentStep === 1){
			disabled = true;
		}

		if (this.currentStep === 9) {
			disabled = this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
		  }
		 return disabled;
		}

	validAccessori() {
		let disabled = false;
		if (!this.formController.validAccessori(this.form) && this.currentStep === 5) {
			disabled = true;
		}
		return disabled;
	}

	validPrivacy(): boolean {
		if (!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd) {
			return false;
		} else
			return true;
	}

	validNuovoPiattoDoccia(): boolean {
		if (this.form.sections.piattoDoccia.colorepiattolargId && !this.form.sections.piattoDoccia.misurapiattolargId) {
			return false;
		}
		if (!this.form.sections.piattoDoccia.colorepiattolargId && !this.form.sections.piattoDoccia.misurapiattostrId) {
			return false;
		}
		return true;
	}

	validNuovoBoxDoccia(form: any): boolean {
		let valid = true;
		if (form.sections.boxdoccia.length > 0) {
			for (let i = 0; i < form.sections.boxdoccia.length; i++) {
				if (form.sections.boxdoccia[i].modelloId &&
					form.sections.boxdoccia[i].cristalliId &&
					form.sections.boxdoccia[i].profiloId &&
					form.sections.boxdoccia[i].ingressoId
				) {
					valid = true;
				} else {
					valid = false;
				}
			}
		}
		return valid;
	}

	private validPiattoPietra(): boolean {
		if (this.form.sections.piattoDoccia.colorepiateffpietraId) {
			if(this.form.sections.piattoDoccia.dimpiatteffpietraId){
				return true;
			}else{
				return false;
			}
		}else{
			return true;
		}
	}

	searchBool(type: any, option: any) {
		let optionDoccia = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche).filter(op => (op.descrizione === type));

		if (optionDoccia.find((res: any) => res.id === option.typeId)) {

			if (option.id) {
				//update option
				this.optionService.update(option).subscribe();
			} else {
				//insert option
				this.optionService.save(option).subscribe();
			}

			if (option.selected == true) {
				return true;

			} else if (option.selected == false) {
				return false;
			}
		}
	}

	reload(value: boolean) {
		this.openSign = value;
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}
}
