import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllarmeDTO } from 'src/app/modules/classes/allarme/allarme.dto';
import { AnagraficaDTO, AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { Ordine } from 'src/app/modules/classes/ordine';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AllarmeService } from 'src/app/services/allarme/allarme.service';
import { BrandService } from 'src/app/services/brand/brand.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { PagamentoDTO } from '../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { AnagraficaService } from '../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import * as $ from 'jquery';
import { constants } from 'src/app/modules/classes/constants';
import { AccessoriAllarmeService } from 'src/app/services/accessori-allarme/accessori-allarme.service';
import { OmaggioService } from '../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import { forkJoin } from 'rxjs';

declare var jQuery: any;
@Component({
  selector: 'app-allarmi',
  templateUrl: './allarmi.component.html',
  styleUrls: ['./allarmi.component.scss'],
})
export class AllarmiComponent implements OnInit, DoCheck {

  currentStep: number = 0;
  totalSteps: number[];

  showModalPrint: boolean = false;
  validAnagrafica: boolean = false;
  openSign: boolean = false;
  isLoaded: boolean = false;

  anagrafica: AnagraficaDTO;
  ordine: Ordine;
  allarme: AllarmeDTO;
  trascodifiche: Trascodifica[];
  trascodificheAccessori: Trascodifica[];
  trascodificheModello: Trascodifica[];

  originalForm: any;
  obj1: any;
  obj2: any;
  form: any;

  isFinanziamentoPayment: boolean = false;
  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
    logoUrl: 'assets/img/loghi/mondialAllarmi.png', product: 'allarme', brand: 'mondial'
  }
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[];
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  constructor(
    public formController: FormControllerService,
    private route: ActivatedRoute,
    private anagraficaService: AnagraficaService,
    private brandService: BrandService,
    private orderService: OrdersService,
    private allarmeService: AllarmeService,
    private accessoriAllarmeService: AccessoriAllarmeService,
    private paymentService: PaymentService,
    private omaggioService: OmaggioService,
    private allegatoService: AllegatoService,
  ) {
    this.showModalPrint = false;
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        allarme: new AllarmeDTO(),
        omaggio: [],
        accessoriAllarme: [],
        pagamento: [new PagamentoDTO()],
        finanziamentoSections: {
          finanziamento: new Finanziamento(),
          finAnagrafica: new FinAnagrafica(),
          finDatiOccupazione: new FinDatiOccupazione(),
          finAnagraficaGarante: new FinAnagrafica(),
          finDatiOccupazioneGarante: new FinDatiOccupazione(),
          isAllegatoSaved: false
        },
      },
      ordine: null
    }

    this.totalSteps = Array(7).fill(1).map((x, i) => i);
  }

  ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));

    if (localStorage.getItem('id_client') !== null) {
      this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
    }
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    this.getOrder(this.form.idOrder);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);

      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }

      localStorage.setItem('id_order', res.idordine);
    });

    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.trascodificheAccessori = this.trascodifiche.filter(item => item.codice === constants.ACCESSORI_ALLARME)
      this.trascodificheModello = this.trascodifiche.filter(item => item.codice === constants.ALLARME_KIT_BASE)
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
    });

    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);

    this.isLoaded = true;
  }

  saveModule(value): void {
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
            this.setStep(this.currentStep + 1);
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          });
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar(constants.ERROR_GENERIC_API);
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe(() => {
            this.setStep(this.currentStep + 1);
          });
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar(constants.ERROR_GENERIC_API);
        });
      }
    } else if (this.currentStep === 1) {
      this.allarme = this.form.sections.allarme;
      const saveOrUpdateRequests = [];

      if (this.hasValues(this.allarme)) {
        this.allarme.ordineId = this.form.idOrder;

        if (this.allarme.id === null || this.allarme.id === undefined) {
          saveOrUpdateRequests.push(this.allarmeService.save(this.allarme));
        } else {
          saveOrUpdateRequests.push(this.allarmeService.update(this.allarme));
        }
      }

      if (this.form.sections.omaggio && this.form.sections.omaggio.length) {
        this.form.sections.omaggio.forEach(element => {
          element.ordineId = this.form.idOrder;
          if (element.id === null || element.id === undefined) {
            saveOrUpdateRequests.push(this.omaggioService.save(element));
          } else {
            saveOrUpdateRequests.push(this.omaggioService.update(element));
          }
        });
      }

      if (saveOrUpdateRequests.length > 0) {
        forkJoin(saveOrUpdateRequests).subscribe({
          next: (results: AllarmeDTO[]) => {
            if (this.hasValues(this.allarme)) {
              this.allarme = results[0];
            }

            if (this.form.sections.omaggio && this.form.sections.omaggio.length) {
              this.form.sections.omaggio = results.slice(1);
            }

            this.setStep(this.currentStep + 1);
          },
          error: (error) => {
            console.error('Errore durante il salvataggio o aggiornamento:', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          }
        });
      }
    } else if (this.currentStep === 2) {
      if (this.form.sections.accessoriAllarme && this.form.sections.accessoriAllarme.length) {
        const saveOrUpdateRequests = this.form.sections.accessoriAllarme.map(element => {
          if (element && (element.id === null || element.id === undefined)) {
            element.ordineId = this.form.idOrder;
            return this.accessoriAllarmeService.save(element);
          } else {
            return this.accessoriAllarmeService.update(element);
          }
        });

        const orderUpdate = this.orderService.update(JSON.stringify(this.form.ordine));

        forkJoin([...saveOrUpdateRequests, orderUpdate]).subscribe({
          next: (results) => {
            results.forEach((res, index) => {
              if (this.form.sections.accessoriAllarme[index]) {
                this.form.sections.accessoriAllarme = res;
              }
            });

            this.setStep(this.currentStep + 1);
          },
          error: (error) => {
            console.error('Errore durante il salvataggio:', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          }
        });
      } else {
        if(this.form.ordine?.note){
          const orderUpdate = this.orderService.update(JSON.stringify(this.form.ordine));

          forkJoin([orderUpdate]).subscribe({
            next: (results) => {
              this.setStep(this.currentStep + 1);
            },
            error: (error) => {
              console.error('Errore durante il salvataggio:', error);
              this.formController.openSnackBar(constants.ERROR_GENERIC_API);
            }
          })
        }else{
          this.setStep(this.currentStep + 1);
        }
      }
    }
    else if (this.currentStep === 3) {
      // Step salvataggio pagamento
      if (this.hasValues(this.form.sections.pagamento[0])) {
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe(res => {
            this.form.sections.pagamento[0] = res;
            this.setStep(this.currentStep + 1);
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          });
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
            this.form.sections.pagamento[0] = res;
            this.setStep(this.currentStep + 1);
            // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
            if (res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
              this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                allegati.forEach((allegato: any) => {
                  if (allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)) {
                    this.allegatoService.delete(allegato.id).subscribe(() => {
                    }, error => {
                      console.error('Error => ', error);
                      this.formController.openSnackBar(constants.ERROR_GENERIC_API);
                    });
                  }
                })
              })
            }
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar(constants.ERROR_GENERIC_API);
          });
        }
      }
    } else if (this.currentStep === 4) {
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe(() => {
        this.setStep(this.currentStep + 1);
      }, error => {
        console.error('Error => ', error);
        this.formController.openSnackBar(constants.ERROR_GENERIC_API);
      });
    }
    else if (this.currentStep === 5) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.anagrafica = res;
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
        this.setStep(this.currentStep + 1);
      }, error => {
        console.error('Error => ', error);
        this.formController.openSnackBar(constants.ERROR_GENERIC_API);
      });
    } else if (this.currentStep === 6 && this.form.sections.finanziamentoSections.isAllegatoSaved) {
      // SOLO NEXT-STEP | PARTE FINANZIAMENTO
      this.setStep(this.currentStep + 1);
    }
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if (this.form.sections.pagamento[0]!.tipopagsaldoId) {
      let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
      if (tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)) {
        this.isFinanziamentoPayment = true;
        this.totalSteps = Array(7).fill(1).map((x, i) => i);
      } else {
        this.isFinanziamentoPayment = false;
        this.totalSteps = Array(6).fill(1).map((x, i) => i);
      }
    }

    this.scrollTop();
  }

  stepController(): boolean {
    let disabled = false;

    if (!this.validAnagrafica && this.currentStep === 0) {
      disabled = true;
    }
    if (this.currentStep === 1 && (!this.checkQtaModello() || !this.checkQtaOmaggi())) {
      disabled = true;
    }
    if (this.currentStep === 2 && (!this.checkQtaAccessori() || this.formController.checkNoteLength(this.form.ordine.note, 255))) {
      disabled = true;
    }
    if (!this.formController.validPagamento(this.form, false, false) && this.currentStep === 3) {
      disabled = true;
    }
    if (this.currentStep === 4 && !this.formController.validPrivacy(this.form)) {
      disabled = true;
    }

    if (this.currentStep === 6 && this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie)) {
      disabled = true;
    }

    return disabled;
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  edit() {
    this.currentStep = 0;
    this.openSign = false;
    this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
      res.statopraticaCodice = "A";
      res.statopraticaId = 2;
      this.orderService.update(JSON.stringify(res)).subscribe(res => {
        this.getOrder(this.form.idOrder);
      });
    })
  }

  reload(value: boolean) {
    this.openSign = value;
  }

  checkQtaModello(): boolean {
    if (this.form.sections.allarme && this.form.sections.allarme.qta > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkQtaOmaggi(): boolean {
    return this.formController.validOmaggio(this.form);
  }

  checkQtaAccessori(): boolean {
    if (this.form.sections.accessoriAllarme && this.form.sections.accessoriAllarme.length > 0) {
      return this.form.sections.accessoriAllarme.every((res: any) => res.tipologiaId && res.qta);
    } else {
      return true;
    }
  }
}
