import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MainComponent } from '../../../main/main.component';
import { OrdersService } from '../../../services/orders/orders.service';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { environment } from 'src/environments/environment';
import { constants } from '../../classes/constants';

@Component({
  selector: 'app-service-activation-order-list',
  templateUrl: './service-activation-order-list.component.html',
  styleUrls: ['./service-activation-order-list.component.scss']
})
export class ServiceActivationOrderListComponent implements OnInit {

  displayedColumns: string[] = ['idOrdine', 'nomeProdotto', 'brandDescrizione', 'dataordine', 'clienteCf', 'statoOrdineDescrizione','statopraticaDescrizione', 'actions'];
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  contracts: any;
  nameUser: any;
  listStatoPratica: any;
  clonedList: any;

  private path = '/new-service-activation/';

  public constants = constants;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isApiV3 = environment.isV3;
  
  constructor(
    private router: Router,
    private orderService: OrdersService,
    private mainCompo: MainComponent,
    private formController: FormControllerService,
  ) {
  }

  ngOnInit(): void {
    if (this.mainCompo.loggedUser) {
      this.getContracts();
    }
  }

  getContracts(): any {
    this.loading = true;

    this.orderService.getOrders(this.mainCompo.loggedUser.id, `size=10000&prodottoCommessa.in=${constants.COMMESSA_ARIEL_DEPURATORE}&prodottoCommessa.in=${constants.COMMESSA_MONDIAL_ALLARME}&statopraticaCodice.in=F&venditoreId.equals=`).subscribe(res => {
      this.loading = false;
      this.contracts = res;
      this.clonedList = [...this.contracts];
      this.nameUser = this.mainCompo.loggedUser.firstName + ' ' + this.mainCompo.loggedUser.lastName;
      this.contracts.forEach(r => {
        r.statopraticaDescrizione = this.capitalize(r.statopraticaDescrizione);
        r.brandDescrizione = this.capitalize(r.brandDescrizione);
        r.nomeProdotto = this.returnProdName(r.prodottoCommessa);
      });
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, error => {
      this.loading = false;
      console.error('Error => ', error);
    });
  }


  capitalize(value): string {
    return this.formController.capitalize(value);
  }


  goToOrder(value, idOrder): void {

    if (value === constants.COMMESSA_MONDIAL_ALLARME) {
      //MONDIAL ALLARME
      this.router.navigate([this.path + 'MONDIAL/allarme/' + idOrder]);
    }else if(value === constants.COMMESSA_ARIEL_DEPURATORE){
      //ARIEL DEPURATORE
      this.router.navigate([this.path + 'ARIEL/depuratore/' + idOrder]);
    }
  }

  returnProdName(opt): string {
    return this.formController.returnProdName(opt);
  }

  createOrder(id, gdlid): void {
    this.router.navigate(['create-order/' + id]);
    localStorage.removeItem('id_client');
    localStorage.setItem('id_gdl', gdlid);
  }

  uploadDocument(id, gdlid): void {
    this.router.navigate(['upload-document/' + id + '/' + gdlid]);
    localStorage.removeItem('id_client');
  }

  hasMenuItems(element: any): boolean {
    const condizione1 = element.statopraticaCodice === 'F' && 
                       element.attivazioneServizi?.statorichiestaCodice !== 'F' && 
                       element.attivazioneServizi?.statorichiestaCodice !== 'R';
  
    const condizione2 = element.attivazioneServizi?.statorichiestaCodice === 'F';
  
    return condizione1 || condizione2;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    const filterIng = (event.target as HTMLInputElement).value.toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (event) {
      this.clonedList = [];
      this.contracts.forEach(c => {
        if (
          c.brandDescrizione?.toLowerCase().includes(filterIng) ||
          c.cognome?.toLowerCase().includes(filterIng) ||
          c.nomeProdotto?.toLowerCase().includes(filterIng) ||
          c.idordine?.toLowerCase().includes(filterIng)
        ) {
          this.clonedList.push(c);
        }
      });
      this.dataSource = new MatTableDataSource(this.clonedList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.contracts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

}