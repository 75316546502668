<div class="pt-5 text-center mb-4">
	
</div>

<div class="text-center mb-4">
	<h4>IMPERSONIFICA UTENTE</h4>
</div>


<div class="with-border" *ngIf="userList">
	<div class="padding15">
		<mat-form-field class="" appearance="standard"> <mat-label>Cerca
		utente</mat-label> <input matInput (keyup)="applyFilter($event)" #input> </mat-form-field>
	</div>
	<div class="tableCont">
		<mat-progress-bar *ngIf="loading" mode="buffer"></mat-progress-bar>
		<table class="customTable" mat-table [dataSource]="dataSource" matSort matSortActive="dataordine" matSortDirection="desc">


			<ng-container matColumnDef="login">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Login</th>
				<td mat-cell *matCellDef="let element">{{ element.login }}</td>
			</ng-container>

			<ng-container matColumnDef="activated">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Attivato</th>
				<td mat-cell *matCellDef="let element">
					<mat-icon *ngIf="element.activated" color="primary">check_circle</mat-icon>
					<mat-icon *ngIf="!element.activated" color="warn">cancel</mat-icon>
				</td>
			</ng-container>
		
			<ng-container matColumnDef="authorities">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Ruoli</th>
				<td mat-cell *matCellDef="let element">{{ element?.authorities?.length ? element.authorities.join(', ') : '-'}}</td>
			</ng-container>


			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef> Impersonifica </th>
				<td mat-cell *matCellDef="let element">
					<button color="primary" class="btnMedium mr-2"
							mat-flat-button
							(click)="loginAs(element)">
						<span class="material-icons">input</span>
					</button>
				</td>
			</ng-container>	

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>

	<mat-paginator [pageSizeOptions]="[10, 25, 100]"
		aria-label="Select page"></mat-paginator>
</div>
